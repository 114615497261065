<template>
  <div class="study"
       v-loading="loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.5)">
    <div class="box">
      <div class="title">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{name: 'onlineStudy'}">在线学习</el-breadcrumb-item>
          <el-breadcrumb-item :to="{name: 'onlineStudy', params: {type: 1}}">课程PPT</el-breadcrumb-item>
          <el-breadcrumb-item>{{lesson.groupName}}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="tool">
          <el-input type="number"
                    v-model="currentPage"
                    :min="1"
                    :max="pageCount" /> / {{pageCount}}
          <button @click="pre"><i class="el-icon-caret-left"></i>上一页</button>
          <button @click="next">下一页<i class="el-icon-caret-right"></i></button>
        </div>
      </div>
      <div class="content">
        <el-carousel ref="player"
                     indicator-position="none"
                     :loop="false"
                     :autoplay="false"
                     @change="changeHandler">
          <el-carousel-item v-for="(img, i) in lesson.imgList"
                            :key="i">
            <img class="img"
                 :src="img" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <el-button v-if="lesson.relation && isBuy"
               class="btn"
               type="primary"
               @click="dialogVisible = true">打开视频</el-button>
    <el-dialog :title="lesson.videoName"
               :visible.sync="dialogVisible"
               @close="closeHandler"
               width="60%">
      <video ref="video"
             :src="lesson.videoUrl"
             :poster="lesson.videoPic"
             controls></video>
    </el-dialog>
  </div>
</template>

<script>
import { $lesson } from '@/services'
import { ROOT } from '@/services/base'
export default {
  data () {
    return {
      lesson: {},
      pdfSrc: '',
      currentPage: 1,
      pageCount: 0,
      dialogVisible: false,
      loading: true,
      isBuy: false
    }
  },
  watch: {
    currentPage (nV) {
      this.play(nV - 1)
    }
  },
  created () {
    this.$nextTick(() => {
      document.documentElement.scrollTop = 80
    })
    $lesson.getMenuList().then(result => {
      if (result.code == 0) {
        $lesson.getLesson(this.$route.query.id).then(async res => {
          if (res.code === 0) {
            let isBuy = res.data.isBuy !== -1;
            this.isBuy = isBuy;
            let lesson = {
              ...res.data,
              pdfSrc: `${ROOT}/${res.data.kecheng}`,
              groupName: result.data.find(menu => menu.id == res.data.group).name,
              imgList: res.data.imgList.map(img => `${ROOT}/${img.url}`)
            }
            this.pageCount = res.data.imgList.length;
            if (res.data.relation && isBuy) {
              let vdo = await this.getLesson(res.data.relation)
              this.lesson = {
                ...lesson,
                videoUrl: `${ROOT}/${vdo.kecheng}`,
                videoPic: `${ROOT}/${vdo.kc_img}`,
                videoName: vdo.name
              }
            } else {
              this.lesson = lesson
            }
          }
        }).then(() => {
          this.loading = false
        })
      }
    })
  },
  methods: {
    changeHandler (index) {
      this.currentPage = index + 1
    },
    loadedHandler () {
      this.loading = false;
    },
    pre () {
      this.$refs.player.prev()
    },
    next () {
      this.$refs.player.next()
    },
    play (index) {
      this.$refs.player.setActiveItem(index)
    },
    errorHandler (err) {
      console.log(err)
    },
    getLesson (id) {
      return $lesson.getLesson(id).then(res => {
        return res.data
      })
    },
    closeHandler () {
      this.$refs.video.pause()
    }
  }
}
</script>
<style>
#app > .head,
#app > .bottom {
  display: none;
}
</style>
<style lang="less" scoped>
@import url('@/styles/study.less');
video {
  width: 100%;
}
.el-input {
  width: 80px;
  /deep/ &__inner {
    background: transparent;
    color: #fff;
  }
}
.el-breadcrumb {
  color: #fff;
  /deep/ &__inner {
    color: #fff !important;
  }
}
.content {
  min-height: 20vh;
  background: transparent;
  /deep/ .el-carousel__container {
    height: 40vw;
  }
  .img {
    max-width: 100%;
  }
}
.btn {
  position: fixed;
  z-index: 999;
  right: 30px;
  bottom: 300px;
}
</style>